<template>
  <h1>Object not found</h1>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

